


$('.slideContentfirst').slick({
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    centerPadding: '0',
    prevArrow: false,
    nextArrow: false,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 1
            }
        }
    ]
});
